import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
// Switch is now Routes

import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import Layout from './components/Layout/Layout'
import Enrollment from './pages/Enrollment/Enrollment'

import SignupEntrepreneurship from './pages/SignUpEntrepreneurship/SignupEntrepreneurship';
import SignupEntrepreneurshipTechnology from './pages/SignupEntrepreneurshipTechnology/SignupEntrepreneurshipTechnologry';
import SignupPromotionTrimestral from './pages/SignupPromotionTrimestral/SignupPromotionTrimestral'
import SignupEntrepreneurshipTechnologyEnglishOnlineMonthly from './pages/SignupEntrepreneurshipTechnologyEnglishOnlineMonthly/SignupEntrepreneurshipTechnologyEnglishOnlineMonthly';
import SignupEntrepreneurshipTechnologyEnglishOnlineQuarterly from './pages/SignupEntrepreneurshipTechnologyEnglishOnlineQuarterly/SignupEntrepreneurshipTechnologyEnglishOnlineQuarterly';
import SignupEnglishOnlineMonthly from './pages/SignupEnglishOnlineMonthly/SignupEnglishOnlineMonthly';
import SignupEnglishOnlineQuarterly from './pages/SignupEnglishOnlineQuarterly/SignupEnglishOnlineQuarterly';
import SignupVideogamesOnlineMonthly from './pages/SignupVideogamesOnlineMonthly/SignupVideogamesOnlineMonthly';
import SignupVideogamesOnlineQuarterly from './pages/SignupVideogamesOnlineQuarterly/SignupVideogamesOnlineQuarterly';
import SignupDemoClass from './pages/SignupDemoClass/SignupDemoClass'
import PageNotFound from './pages/PageNotFound/PageNotFound'
import Building from './pages/Building/Building'
// import ImageLoader from './components/ImageLoader/ImageLoader';
// import logo from './logo.svg';
// import './App.css';
import './pages/HomePage/HomePage.scss'

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {/* <Route path="*" element={<Building />}></Route> */}
          <Route element={<Layout />} >
            <Route exact path='/' element={<Enrollment/>} />
            {/* <Route exact path="/plans/:id" element={<Plans />} /> */}
            {/* <Route exact path="/sign-up-entrepreneurship/:id" element={<SignupEntrepreneurship />} /> */}
            {/* <Route exact path="/sign-up-entrepreneurship-technology" element={<SignupEntrepreneurshipTechnology />} /> */}
            <Route exact path="/sign-up-entrepreneurship-technology" element={<Building />} />
            {/* <Route exact path="/sign-up-promotion-trimestral" element={<SignupPromotionTrimestral />} /> */}
            <Route exact path="/sign-up-promotion-trimestral" element={<Building />} />
            {/* <Route exact path="/sign-up-entrepreneurship-technology-english-online-monthly" element={<SignupEntrepreneurshipTechnologyEnglishOnlineMonthly />} /> */}
            <Route exact path="/sign-up-entrepreneurship-technology-english-online-monthly" element={<Building />} />
            {/* <Route exact path="/sign-up-entrepreneurship-technology-english-online-quarterly" element={<SignupEntrepreneurshipTechnologyEnglishOnlineQuarterly />} /> */}
            <Route exact path="/sign-up-entrepreneurship-technology-english-online-quarterly" element={<Building />} />
            {/* <Route exact path="/sign-up-english-online-monthly" element={<SignupEnglishOnlineMonthly />} /> */}
            <Route exact path="/sign-up-english-online-monthly" element={<Building />} />
            {/* <Route exact path="/sign-up-english-online-quarterly" element={<SignupEnglishOnlineQuarterly />} /> */}
            <Route exact path="/sign-up-english-online-quarterly" element={<Building />} />
            {/* <Route exact path="/sign-up-videogames-online-monthly" element={<SignupVideogamesOnlineMonthly />} /> */}
            <Route exact path="/sign-up-videogames-online-monthly" element={<Building />} />
            {/* <Route exact path="/sign-up-videogames-online-quarterly" element={<SignupVideogamesOnlineQuarterly />} /> */}
            <Route exact path="/sign-up-videogames-online-quarterly" element={<Building />} />
            {/* <Route exact path="/sign-up-demo-class" element={<SignupDemoClass />} /> */}
            <Route exact path="/sign-up-demo-class" element={<Building />} />
            <Route exact path="/building" element={<Building />} />
            <Route path="*" element={<PageNotFound />} />
          </Route>
        </Routes>
      </ScrollToTop>
    </BrowserRouter>


    // </Route>
    
    // <AuthProvider>
    //   <BrowserRouter>
    //     <Switch>
    //       <Route exact path="/" component={Home}></Route>
    //     </Switch>
    //   </BrowserRouter>
    // </AuthProvider>
  );
}

export default App;
