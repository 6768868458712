import React from 'react'

import EpicIsotipoGroup from '../../assets/images/Epic-isotipogroup--03.png'

import './building.scss'

const Building = _ => {
  return (
    <main className="home-main">
      <div className="building">
        <div className="building__text-container">
          <h1 className="building__title">Estamos ampliando</h1>
          <h2 className="building__subtitle">
            <br />
            Para brindarte una experiencia aún mejor 😊.
            Mientras tanto, nuestro amable asesor comercial estará encantado de atenderte y responder
            a todas tus consultas haciendo <a
            className='building__button button--third--small'
            href="https://wa.me/+51944547502" target="_blank">
              <p className='paragraph--highlight'>Click Aquí</p>
            </a>
          </h2>
          <h2 className="building__subtitle2">
            ¡Gracias por tu paciencia
            y por ser parte del universo Epic! 🚀
          </h2>
        </div>

        <img className="building__image" src={EpicIsotipoGroup} alt="construccion" />
      </div>
    </main>
  )
}

export default Building